<template>
  <div class="container">
    <Breadcrumb :data="breData" :is$t="true" />
    <div class="content">
      <Search
        ref="search"
        class="search"
        :search-info="searchInfo"
        @search="getData"
      />
      <div v-if="data.length" class="listContent">
        <div v-for="item in data" :key="item.id" class="listWarp">
          <div class="topTitle">
            <div style="margin-bottom: 10px" @click="jumpDetails(item.id)">{{ item.name }}</div>
            <div v-for="type in item.types" :key="type" class="type">{{ type }}</div>
          </div>
          <div class="details">{{ item.content.replace(/<[^>]+>/g, "") }}</div>
          <!-- <div class="details"><div class="details-html" v-html="item.content"></div></div> -->
          <div class="bottom">
            <div class="btmItem">作者：{{ item.author }}</div>
            <div class="btmItem">来源：{{ item.source }}</div>
            <div class="btmItem">发表时间：{{ item.dateline }}</div>
          </div>
        </div>
        <Pagination class="pagination" :page-info="pageInfo" @changePage="changePage" />
      </div>
      <NoData v-else type="content" size="medium" class="no-data" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import NoData from '@/components/NoData'
import Search from "@/components/Search"
import Breadcrumb from '@/components/Breadcrumb'
import API from "@/api/legalResearchResult"
export default {
  name: 'ResearchResultList',
  components: {
    Breadcrumb,
    Pagination,
    NoData,
    Search
  },
  data() {
    return {
      // getType: 'getExpertInterview',
      breData: [
        { name: '首页', path: '/home' },
        { name: '法律研究成果', path: '/researchResult/list' },
      ],
      data: [],
      searchInfo: {
        keyword: ''
      },
      index: 0,
      pageInfo: {
        page: 1,
        size: 8,
        total: 100,
      },
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    const name = this.$route.query?.type || ''
    if (name) {
      this.searchInfo.keyword = name
    }
    this.getData(true)
  },
  methods: {
    getData(isSearch) {
      this.loading = true
      const { pageInfo: { size, currentPage: page }} = this
      API.getLegalResearchResultPage({
        size,
        page: isSearch ? 1 : page,
        keyword: this.searchInfo.keyword,
      }).then(res => {
        const data = res.content
        data.map((item) => {
          const types = item?.type ? item.type.split(",") : []
          types.push(item.country)
          item.types = types
        })

        this.data = data
        this.loading = false
        this.pageInfo.total = res.page.total
      })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getData()
    },
    jumpDetails(id) {
      id && this.$router.push({
        path: `/researchResult/details?id=${id}`,
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.container{
  width: @uni-width;
  margin: auto;
}
.content {
  width: @uni-width;
  margin: 0 auto 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 22px 0px rgba(142,142,142,0.18);
  padding: 30px;
  .search{
    margin: 0 !important;
  }
  .listWarp{
    border-bottom: 1px dotted rgba(0, 0, 0, 0.09);
    padding: 30px 0;
    &:first-child{
      padding-top: 10px;
    }
    .topTitle{
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      display: flex;
    align-items: center;
    flex-wrap: wrap;
      .type{
        font-size: 12px;
        padding: 4px 6px;
        display: inline-block;
        color: #FF851F;
        background-color: #FFF2E7;
        margin-left: 10px;
        margin-bottom: 10px;
        &:nth-child(n){
          color: #FF851F;
          background-color:  #FFF2E7;
        }
        &:nth-child(2n){
          color: #8B64FF;
          background-color: #F1ECFF;
        }
        &:nth-child(3n){
          color: #03B56E;
          background-color: #E5F7F0;
        }
        &:nth-child(4n){
          color: #4079FF;
          background-color: #EBF1FF;
        }
        &:nth-child(5n){
          color: #FF6E3F;
          background-color: #FFF0EB;
        }
      }
    }
    .details{
      font-size: 16px;
      color: #666666;
      margin: 10px 0 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .details-html{
        max-width: calc(100% - 20px);
        width: calc(100% - 20px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .bottom{
      display: flex;
      align-items: center;
      .btmItem{
        font-size: 14px;
        color: #999999;
        // width: 250px;
        margin-right: 100px;
      }
    }
  }

  .pagination{
    margin-top: 30px;
  }

  .no-data, .listContent{
    min-height: calc(100vh - 446px);
    justify-content: center;
  }
}
</style>
